header{
    height: 150vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

/*============================CTA==========================*/

.cta{
    margin-top: 2.5rem;
    margin: 2rem 1rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;

}

/*================================== H S =============================*/
.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8ram;
    position: absolute;
    left: 0;
    bottom: 3rem;
}
.header__socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/*============================== IMAGE =======================================*/
.me{
   
    width: 24rem;
    height: 35rem;
    position: absolute;
    left: calc(50% - 11rem);
   
    border-radius:  12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem -1.5rem 1.5rem;
}
.photo{
    border-radius: 12rem 12rem 0 0;
    width: 22rem;
    height: 30rem;
    margin-top: 0.1rem;
    overflow: hidden;
  
   
    left: calc(50% - 11rem);
  
  
    
    
}


/*==========================SCROOL DOWN ==========================*/
.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/*================================= MEDIA QUERIES (MEDUIM DEVICES) =============================*/

@media screen  and (max-width:1024px){
   header{
    height: 150vh;
   }
    
}
/*================================= MEDIA QUERIES (SMALL DEVICES) =============================*/
@media screen  and (max-width:600px){
    header{
        height: 160vh;
        
       }
       .header__socials,
       .scroll__down{
        display:none;
        
       }

      
    
}

