nav{
    background: rgba(0,0,0,0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 1.9rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
    height: 58px;


}
nav a {
    background: transparent;
    padding: 0,9;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.6rem;

} 

nav a:hover{
    background: rgba(255, 255, 255, 0.3);
    width: 20px;
    height: 20px;
    width: 40px;
    height: 40px;
    padding: 10px;

}
nav a.active{
    background: rgba(255, 255, 255, 0.3);
    color: var(--color-white);
    width: 40px;
    height: 40px;
    padding: 10px;
}



@media screen  and (max-width:600px){
  
       nav{
      
        gap: 1rem;
      
       

       }
   }
